import {
  InputLabel,
  Typography,
  css,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  Card,
  FormGroup,
  FormHelperText,
  TextField as MUIText,
  Select,
  MenuItem,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  BooleanField,
  DateField,
  EditButton,
  NumberField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  TextField,
  TopToolbar,
  useNotify,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import WinnersList from './WinnersList';
import BoyAPI from 'services/boy-api';
import { CancelReasonSelect, Game, TournamentCancelReason } from 'core/types';
import { GppBadSharp } from '@mui/icons-material';
import { ButtonGroup } from 'rsuite';
import Parser from 'utils/parser';

const useStyles = makeStyles()((theme) => ({
  tabbedShowLayout: {
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      display: 'flex',
    },
  },
  root: {
    marginTop: '32px',
  },
}));

const ShowActions = (props: any) => {
  const { id } = props;
  const { permissions } = usePermissions();
  const notify = useNotify();
  const [cancelling, setCancelling] = useState<boolean>(false);
  const [cancelCheck, setCancelCheck] = useState<string>('');
  const [cancelReason, setCancelReason] = useState<
    TournamentCancelReason | undefined
  >(undefined);
  const record = useRecordContext();

  const reset = () => {
    setCancelling(false);
    setCancelCheck('');
    setCancelReason(undefined);
  };

  const startCancel = () => {
    reset();
    setCancelling(true);
  };

  const cancelTournament = () => {
    try {
      BoyAPI.Tournament.cancel(id, cancelReason);
    } catch (error) {
      let [err, errType] = Parser.error(error);
      reset();
      notify(err, errType);
      return;
    }
    reset();
    notify('Canceled', { type: 'success' });
  };

  if (
    !permissions ||
    !permissions.admin ||
    !record ||
    !['EDITABLE', 'IN_PROGRESS'].includes(record.state)
  ) {
    return null;
  }
  return (
    <TopToolbar>
      <Dialog open={cancelling} onClose={reset}>
        <DialogTitle>Cancelling tournament</DialogTitle>
        <Card sx={{ padding: '1.5rem' }}>
          <Typography
            sx={{ fontSize: '1.3rem' }}
            variant="subtitle1"
            component="div">
            Type{' '}
            <b>
              <i>cancel</i>
            </b>{' '}
            in box bellow to confirm cancellation. A cancelled tournament{' '}
            <b>cannot be openned again</b>.
          </Typography>
          <FormGroup>
            <MUIText
              id="cancel_check"
              required
              value={cancelCheck}
              onChange={(event: any) => setCancelCheck(event.target.value)}
              onPaste={(e) => e.preventDefault()}
            />
          </FormGroup>
          <FormGroup>
            <FormHelperText>Cancel reason*</FormHelperText>
            <Select
              id="selected_game"
              required
              value={cancelReason}
              onChange={(event: any) => setCancelReason(event.target.value)}>
              {CancelReasonSelect.map((reason) => {
                return (
                  <MenuItem id={reason} key={reason} value={reason}>
                    {reason}
                  </MenuItem>
                );
              })}
            </Select>
          </FormGroup>
          <FormGroup sx={{ textAlign: 'center', margin: '10px 0 0' }}>
            <ButtonGroup variant="contained" sx={{ justifyContent: 'center' }}>
              <Button
                disabled={cancelCheck !== 'cancel' || !cancelReason}
                onClick={cancelTournament}>
                Confirm !
              </Button>
              <Button onClick={reset} color="secondary">
                Cancel
              </Button>
            </ButtonGroup>
          </FormGroup>
        </Card>
      </Dialog>
      {record && record.state === 'EDITABLE' && <EditButton />}
      {record && ['EDITABLE', 'IN_PROGRESS'].includes(record.state) && (
        <Button size="small" color="secondary" onClick={startCancel}>
          <GppBadSharp />
          &nbsp;Cancel
        </Button>
      )}
    </TopToolbar>
  );
};

const GameFields = ({ games }: { games: { [key: string]: Game } }) => {
  const [game, setGame] = useState<Game | undefined>(undefined);
  const [gameRef, setGameRef] = useState<string>('');

  const notify = useNotify();
  const record = useRecordContext();

  useEffect(() => {
    setGameRef(record.game_id);
    if (record.game_id in games) {
      setGame(games[record.game_id]);
    }
  }, [record, games, notify]);

  return (
    <div>
      <InputLabel style={{ fontSize: 12 }}>Game</InputLabel>
      {game ? (
        <Typography variant="body2">
          <img src={game?.logo_url} alt="" width="80px" />
          {game?.name}
        </Typography>
      ) : (
        <Typography style={{ marginTop: 8 }} variant="body2">
          id: {gameRef}
        </Typography>
      )}
    </div>
  );
};

const TournamentDetail = (props: ShowProps) => {
  const notify = useNotify();
  const isXlScreen = useMediaQuery('(min-width: 600px)');
  const { id } = useParams();
  const { classes } = useStyles();

  const [games, setGames] = useState<{ [key: string]: Game }>({});

  useEffect(() => {
    (async () => {
      try {
        const _games = await BoyAPI.Games.list();
        setGames(
          Object.fromEntries(
            _games.data.map((game) => {
              return [game.ref, game];
            }),
          ),
        );
      } catch (error) {
        notify(
          error.response.data.error ||
            error.response.data.detail ||
            'Could not retrieve games: unkow reson',
          {
            type: error.response.status >= 500 ? 'error' : 'warning',
          },
        );
      }
    })();
  }, [notify]);

  return (
    <Show {...props} actions={<ShowActions id={id} />} className={classes.root}>
      <TabbedShowLayout
        className={classes.tabbedShowLayout}
        sx={{
          '& .RaTabbedShowLayout-content': css`
            padding: 8px 16px;
            flex: 1;
          `,
        }}
        tabs={
          <TabbedShowLayoutTabs
            orientation={isXlScreen ? 'vertical' : 'horizontal'}
          />
        }>
        <Tab label="summary">
          <TextField source="id" />
          <GameFields games={games} />
          <NumberField source="reward_pool" />
          <TextField source="reward_repartition" />
          <DateField source="start_at" showTime />
          <DateField source="end_at" showTime />
          <DateField source="generated_at" showTime />
          <TextField source="generated_from_settings" />
          <DateField source="last_updated_at" showTime />
          <TextField source="state" />
          <BooleanField
            source="reward_locked"
            style={{
              alignSelf: 'center',
            }}
          />
          <TextField source="cancelled_reason" />
          <DateField source="cancelled_at" />
        </Tab>
        <Tab label="winners">{id && <WinnersList tournamentId={id} />}</Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default TournamentDetail;
