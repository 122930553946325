import {
  useMediaQuery,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  Card,
  FormGroup,
  FormHelperText,
  Select,
  MenuItem,
  TextField as MUIText,
  Radio,
  RadioGroup,
  FormControlLabel,
  CardHeader,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableFooter,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import RAList from 'components/common/RAList';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  Filter,
  NumberField,
  SelectInput,
  SimpleList,
  TextField,
  useNotify,
} from 'react-admin';
import { TextField as CommonText, ObjectField } from 'components/common/fields';
import dateUtils from 'utils/date';
import BoyAPI from 'services/boy-api';
import { Game, RewardUnit, TournamentSetting } from 'core/types';
import {
  AddCircleOutline,
  AddSharp,
  Delete,
  MoreTimeSharp,
} from '@mui/icons-material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Parser from 'utils/parser';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: '16px',
    [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
      padding: '16px 0',
    },
  },
}));

type rewardFormPropd = {
  onUpdate: (
    rewardRepartition: { [key: string]: string },
    valid: boolean,
  ) => void;
};

const RewardRepartionForm = ({ onUpdate }: rewardFormPropd) => {
  type formEntry = { range: string; reward: number };
  type rangeState = 'NON_CONTINUOUS' | 'DUPLICATED_VALUES' | 'OK';

  const [rewardSum, setRewardSum] = useState<number>(0);
  const [formLines, setFormLines] = useState<formEntry[]>([
    { range: '', reward: 0 },
  ]);

  const addLine = () => {
    setFormLines([...formLines, { range: '', reward: 0 }]);
  };

  const delLine = (idx: number) => {
    if (idx < formLines.length) {
      let newLines = [...formLines],
        item = formLines[idx];
      newLines.splice(idx, 1);
      setFormLines(newLines);
      setRewardSum(rewardSum - (item.reward || 0));
    }
  };

  const colorReward = () => {
    return rewardSum === 100 ? '#22bb33' : '#FF007C';
  };

  const updateReward = (idx: number, value: number) => {
    let lines = [...formLines];
    let line = lines[idx];
    let reward_diff = value - (line.reward || 0);

    line.reward = value;

    setRewardSum(rewardSum + reward_diff);
    setFormLines(lines);
  };

  const updateRange = (idx: number, value: string) => {
    let lines = [...formLines];
    let line = lines[idx];

    line.range = value;

    setFormLines(lines);
  };

  const rangeCheck = useCallback((): rangeState => {
    let checker: number[] = [],
      res: rangeState = 'OK',
      visited: number[] = [];

    formLines.reduce((acc, line) => {
      if (line.range) {
        let splitted = line.range.split('-');
        if (splitted.length === 1 || splitted[1] === '') {
          acc.push(Number(splitted[0]));
        } else {
          let rangeStart = Number(splitted[0]),
            rangeStop = Number(splitted[1]),
            tmp = rangeStop;

          if (rangeStart > rangeStop) {
            rangeStop = rangeStart;
            rangeStart = tmp;
          }

          rangeStop++;

          for (let i = rangeStart; i < rangeStop; i++) {
            acc.push(i);
          }
        }
      }
      return acc;
    }, checker);

    checker.sort((a, b) => a - b);

    checker.reduce((acc, item) => {
      if (res !== 'OK') {
        return visited;
      }

      if (acc.includes(item)) {
        res = 'DUPLICATED_VALUES';
        return visited;
      }

      if (acc.length > 0 && item - acc[acc.length - 1] > 1) {
        res = 'NON_CONTINUOUS';
        return visited;
      }

      visited.push(item);
      return visited;
    }, visited);

    return res;
  }, [formLines]);

  useEffect(() => {
    if (!onUpdate) return;

    let jsonRepartition = {};

    formLines.reduce((acc, line) => {
      if (line.range && line.reward) {
        acc[line.range] = `${line.reward}%`.replace(',', '.');
      }
      return acc;
    }, jsonRepartition);

    onUpdate(jsonRepartition, rangeCheck() === 'OK' && rewardSum === 100);
  }, [rewardSum, formLines, onUpdate, rangeCheck]);

  return (
    <FormGroup>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Range</TableCell>
            <TableCell>Reward (%)</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formLines.map((line, idx) => {
            return (
              <TableRow>
                <TableCell>
                  <MUIText
                    type="text"
                    required
                    value={line.range}
                    onChange={(event: any) =>
                      updateRange(idx, event.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  <MUIText
                    type="number"
                    required
                    value={line.reward}
                    onChange={(event: any) =>
                      updateReward(idx, event.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  <Button onClick={() => delLine(idx)} color="secondary">
                    <Delete />
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow />
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>Total rewarded</TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: colorReward() }}>
              {rewardSum}%
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell>Range check</TableCell>
            <TableCell
              sx={{
                fontWeight: 'bold',
                color: rangeCheck() === 'OK' ? '#22bb33' : '#FF007C',
              }}>
              {rangeCheck()}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableFooter>
      </Table>
      <Button onClick={addLine}>
        <AddCircleOutline />
      </Button>
    </FormGroup>
  );
};

const Actions = ({ games }: { games: Game[] }) => {
  const notify = useNotify();
  const [createModalOpened, setCreateModalOpened] = useState<boolean>(false);
  const [isSerieCreation, setCreateSerie] = useState<boolean>(false);

  // create form data
  const [selectedGame, setSelectedGame] = useState<string | undefined>(
    undefined,
  );
  const [rewardPool, setRewardPool] = useState<number | undefined>(undefined);
  const [endAt, setEndAt] = useState<Date | null>(null);
  const [startAt, setStartAt] = useState<Date | null>(null);
  const [rewardRepartition, setRewardRepartion] = useState<
    { [key: string]: string } | undefined
  >(undefined);
  const [validRewardRepartition, setValidRewardRepartition] = useState<boolean>(
    false,
  );
  const [rewardUnit, setRewardUnit] = useState<RewardUnit | undefined>(
    RewardUnit.GOLDS,
  );
  const [configName, setConfigName] = useState<string | undefined>(undefined);
  const [settings, setSettings] = useState<TournamentSetting[]>([]);

  const startCreate = (is_serie: boolean) => {
    setCreateSerie(is_serie);
    setCreateModalOpened(true);
  };

  useEffect(() => {
    (async () => {
      try {
        const settings = await BoyAPI.Tournament.listSettings();
        setSettings(settings.data);
      } catch (error) {
        let [err, errType] = Parser.error(error);
        notify(err, errType);
      }
    })();
  }, [notify]);

  const createTournament = () => {
    try {
      if (isSerieCreation) {
        BoyAPI.Tournament.startSerie(
          selectedGame,
          startAt,
          endAt,
          rewardPool,
          rewardUnit,
          rewardRepartition,
          configName,
        );
      } else {
        BoyAPI.Tournament.create(
          selectedGame,
          startAt,
          endAt,
          rewardPool,
          rewardUnit,
          rewardRepartition,
        );
      }
    } catch (error) {
      let [err, errType] = Parser.error(error);
      notify(err, errType);
      return;
    }
    reset();
    setCreateModalOpened(false);
  };

  const onRewardRepUpdate = useCallback(
    (
      rep: {
        [key: string]: string;
      },
      valid: boolean,
    ) => {
      setRewardRepartion(rep);
      setValidRewardRepartition(valid);
    },
    [],
  );

  const reset = () => {
    setCreateSerie(false);
    setCreateModalOpened(false);
    setConfigName(undefined);
    setRewardUnit(undefined);
    setRewardRepartion(undefined);
    setStartAt(null);
    setEndAt(null);
    setRewardPool(undefined);
    setSelectedGame(undefined);
    setValidRewardRepartition(false);
  };

  return (
    <>
      <Dialog
        open={createModalOpened}
        fullWidth
        sx={{ overflow: 'visible' }}
        onClose={() => {
          setCreateModalOpened(false);
        }}>
        <DialogTitle>
          Create tournament {isSerieCreation && 'serie'}
        </DialogTitle>
        <Card sx={{ overflow: 'auto', padding: '1rem' }}>
          <FormGroup>
            <FormHelperText>
              {isSerieCreation ? 'Starting game' : 'Game'}*
            </FormHelperText>
            <Select
              id="selected_game"
              required
              value={selectedGame}
              onChange={(event: any) => setSelectedGame(event.target.value)}>
              <MenuItem id="null" key="" value=""></MenuItem>
              {games.map((game) => {
                return (
                  <MenuItem id={game.id} key={game.ref} value={game.ref}>
                    <img src={game?.logo_url} alt="" width="60px" /> {game.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormGroup>
          <FormGroup>
            <FormHelperText>Reward pool*</FormHelperText>
            <MUIText
              id="reward_pool"
              type="number"
              required
              value={rewardPool}
              onChange={(event: any) => setRewardPool(event.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <FormHelperText>Reward unit*</FormHelperText>
            <Select
              id="reward_unit"
              required
              value={rewardUnit}
              onChange={(event: any) => setRewardUnit(event.target.value)}>
              {Object.entries(RewardUnit).map(([key, name]) => {
                return (
                  <MenuItem id={key} key={key} value={name}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormGroup>
          <FormGroup>
            <FormHelperText>Reward repartition*</FormHelperText>
            <RewardRepartionForm onUpdate={onRewardRepUpdate} />
          </FormGroup>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormGroup>
              <FormHelperText>Start at*</FormHelperText>
              <DateTimePicker
                value={startAt}
                onChange={(newValue) => {
                  setStartAt(newValue);
                }}
                disablePast={true}
                renderInput={(params) => <MUIText {...params} />}
              />
            </FormGroup>
            <FormGroup>
              <FormHelperText>End at*</FormHelperText>
              <DateTimePicker
                value={endAt}
                onChange={(newValue) => {
                  setEndAt(newValue);
                }}
                disablePast={true}
                renderInput={(params) => <MUIText {...params} />}
              />
            </FormGroup>
          </LocalizationProvider>
          {isSerieCreation && (
            <>
              <FormGroup>
                <FormHelperText id="config_name_label">Setting*</FormHelperText>
                <RadioGroup
                  row
                  id="config_name"
                  name="config_name_group"
                  onChange={(event: any) => setConfigName(event.target.value)}>
                  {settings.map((setting) => {
                    return (
                      <FormControlLabel
                        sx={{ margin: '0.5rem' }}
                        value={setting.name}
                        control={<Radio />}
                        label={
                          <Card
                            style={{ backgroundColor: '#13364f' }}
                            sx={{
                              padding: '1rem',
                            }}
                            raised={configName === setting.name}>
                            <CardHeader title={setting.name} />
                            <CommonText
                              label="duration"
                              data={setting.duration.toString() + ' days'}
                            />
                            <CommonText
                              label="reward pool"
                              data={setting.reward_pool.toString()}
                            />
                            <ObjectField
                              label="reward rep"
                              data={setting.reward_repartition}
                            />
                            <ObjectField
                              label="games order"
                              data={setting.games}
                            />
                          </Card>
                        }
                        checked={configName === setting.name}
                      />
                    );
                  })}
                </RadioGroup>
              </FormGroup>
            </>
          )}
          <FormGroup sx={{ textAlign: 'center', margin: '10px 0 0' }}>
            <ButtonGroup sx={{ justifyContent: 'center' }}>
              <Button
                disabled={
                  !selectedGame ||
                  !rewardPool ||
                  !endAt ||
                  !startAt ||
                  !validRewardRepartition ||
                  (isSerieCreation && !configName)
                }
                onClick={createTournament}>
                Create
              </Button>
              <Button color="secondary" onClick={reset}>
                Cancel
              </Button>
            </ButtonGroup>
          </FormGroup>
        </Card>
      </Dialog>
      <ButtonGroup variant="text" size="small">
        <Button size="small" onClick={() => startCreate(false)}>
          <AddSharp />
          &nbsp;New
        </Button>
        <Button size="small" onClick={() => startCreate(true)}>
          <MoreTimeSharp />
          &nbsp;New&nbsp;serie
        </Button>
      </ButtonGroup>
    </>
  );
};

export const TournamentsList = (props: any) => {
  const notify = useNotify();
  const { classes } = useStyles();
  const matches = useMediaQuery('(min-width:600px)');

  const [games, setGames] = useState<Game[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const _games = await BoyAPI.Games.list();
        setGames(_games.data);
      } catch (error) {
        notify(
          error.response.data.error ||
            error.response.data.detail ||
            'Could not retrieve games: unkow reson',
          {
            type: error.response.status >= 500 ? 'error' : 'warning',
          },
        );
      }
    })();
  }, [notify]);

  const datagrid = useMemo(() => {
    if (matches) {
      return (
        <Datagrid rowClick="show" optimized>
          <TextField source="game_id" sortable={false} />
          <NumberField source="reward_pool" sortable={false} />
          <TextField source="reward_repartition" sortable={false} />
          <DateField source="start_at" />
          <DateField source="end_at" />
          <TextField source="state" sortable={false} />
          <BooleanField source="reward_locked" sortable={false} />
        </Datagrid>
      );
    }
    return (
      <SimpleList
        linkType={'show'}
        primaryText={(record) => record.game_id}
        secondaryText={(record) => record.reward_pool}
        tertiaryText={(record) =>
          dateUtils.localizedFormat(new Date(record.start_at), 'Ppp')
        }
      />
    );
  }, [matches]);
  return (
    <RAList
      className={classes.root}
      exporter={false}
      actions={<Actions games={games} />}
      perPage={25}
      total={10}
      sort={{ field: 'start_at', order: 'DESC' }}
      filters={
        <Filter>
          <SelectInput
            alwaysOn
            resettable
            source="state"
            choices={[
              { id: 'IN_PROGRESS', name: 'In Progress' },
              { id: 'EDITABLE', name: 'Editable' },
              { id: 'COMPLETED', name: 'Completed' },
              { id: 'CANCELLED', name: 'Cancelled' },
            ]}
          />
          <SelectInput
            alwaysOn
            resettable
            source="game_ids"
            choices={games.map((game) => {
              return { id: game.ref, name: game.name };
            })}
          />
        </Filter>
      }
      {...props}>
      {datagrid}
    </RAList>
  );
};

export default TournamentsList;
